@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body {
    font-size: 21px;
    background-color: #fff;
    font-family: 'Inter', 'Spoqa Han Sans Neo', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: #000;
    word-break: keep-all;
   
}

button {
    font-size: inherit;
    background-color: inherit;
    border: none;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    cursor: pointer;

}
input:focus{
    outline: none;
}
textarea{
    font-size: inherit;
    font-family: inherit;
    resize: none;
}
ul,
li {
    list-style: none;
}
a{
    color:inherit;
    text-decoration: inherit;
}

* {
    margin: 0px;
    padding: 0px;
    cursor:none !important;
}
.ani_t_line,.ani_t_line2{
    word-break: keep-all;
    overflow-y: hidden;
}
.mobile_view{
    display:none;
}
@keyframes ani_show {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.swiper{
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom:40px;
        display: flex;
justify-content: center;
gap: 3px;
    }
    
    .swiper-pagination-bullet{
        width:8px;
        height:8px;
        background-color: #fff;
        opacity: 0.3;
        margin:0px 6px;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
     
        opacity: 1;
    }
}
.loading_section{
    line-height: 400px;
    font-size:14px;
    color:#aaa;
    text-align: center;
    font-weight:500;
    animation: t_ani 2s infinite;
    @keyframes t_ani {
        0%{
            opacity: 1;
        }
        50%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
        
    }
}
.contents_img{
    text-align: center;
    margin:10px 0px;
}
.no_contents{
    line-height: 400px;
    font-size:14px;
    color:#aaa;
    text-align: center;
    font-weight:500;
}

.mouse_pointer{
    position: fixed;
    width:10px;
    height:10px;
    background-color:#eee;
    z-index: 999999;
    border-radius: 50%;
    left:20%;
    top:50%;
    mix-blend-mode: difference;
    pointer-events: none;
    
}
.t_en{
    display:none;
}
.en_languege{
    .about.a4{
        .info_item .info_title{
            width:270px !important;
        }
    }
    &.contents_wrap .section_container .section_contents .sc_items .sc_item .sc_title{
        width:280px;
    }
    .t_kr{
        display:none;
    }
    .t_en{
        display:initial;
    }
    &.contents_wrap{
        .artist{
            .artist_items{
                &.name_items{
                    row-gap:1.2vw;
                    .ar_item{
                        font-size:3vw;
                    }
                }
            }
        }
    }
}
.header {
    position: fixed;
    z-index: 100;
    top: 45px;
    left: 80px;
    right: 80px;
    display: flex;
    justify-content: space-between;
    transition-duration: 1s;
    &.scroll_up{
        top:-80px;
        &.menu_active{
            top: 45px;
        }
    }
  
  
   

    .logo_section {
        position: relative;
        z-index: 91;
        img {
            height: 30px;
        }
    }

    .menu_right_section {
        display: flex;
        gap: 290px;
        align-items: center;
        position: relative;
        z-index: 99;
      
        &.active, &.white {
            &.active{
                .mn_ic{
                    .mn_bar{
                        &:nth-child(1) {
                            transform: translateY(9px) rotate(45deg);
                        }
    
                        &:nth-child(3) {
                            width: 100%;
                            transform: translateY(-9px) rotate(-45deg);
                        }
    
                        &:nth-child(2) {
                            transform: scale(0);
                        }
                    }
                }
            }
            .mn_language {
                border-color: #fff;
                color: #fff;
                &:hover{
                    background-color: #fff;
                    color:#8B00ff;
                }
            }

            .mn_ic {
                .mn_bar {
                    background-color: #fff;

                   
                }
            }
        }

        .mn_language {
            width: 75px;
            height: 32px;
            box-sizing: border-box;
            border: 1px solid #000;
            line-height: 30px;
            font-size: 18px;
            text-align: center;
            border-radius: 32px;
            font-weight: 500;
            cursor: pointer;
            transition-duration: 0.2s;
            &:hover{
                background-color: #000;
                color:#fff;
            }
        }

        .mn_ic {
            width: 36px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            gap: 6px;

            &:hover {
                .mn_bar:last-child {
                    width: 100%;
                }
            }

            .mn_bar {
                width: 100%;
                height: 3px;
                background-color: #000;
                transition-duration: 0.2s;

                &:last-child {
                    width: 70%;
                    transition-duration: 0.2s;
                }
            }

        }
    }

    .menu_pop {
        position: fixed;
        z-index: 90;
        width: 100vw;
        background-color: #8B00ff;
        top: 0px;
        opacity: 0;
        left:0px;
        pointer-events: none;
        bottom: 0px;
        padding: 15vh 80px;
        box-sizing: border-box;
        transition-duration: 0.5s;
        padding-left:40%;
        box-sizing: border-box;

        &.active {
            opacity: 1;
            pointer-events: initial;
        }

        .mnp_menu {
            li {
                color: #fff;
                font-size: 3.7vw;
                font-weight: 800;
                margin-bottom: 10px;
                line-height: 4.5vw;
                letter-spacing: 0.025em;
                border-bottom:1px solid rgba(255,255,255,0.2);
                transition-duration: 0.3s;
                display:flex;
                justify-content: space-between;
                align-items: center;
                img{
                    width:36px;
                    opacity: 0;
                    transition-duration: 0.2s;
                }
                cursor: pointer;
                &:hover{
                    border-bottom:1px solid rgba(255,255,255,1);
                    img{
                        opacity: 1;
                    }
                }
            }
        }
        .mnp_submenu {
            margin-top:50px;
            li {
                color: #fff;
                font-size: 32px;
                font-weight: 600;
                margin-bottom: 16px;
                line-height: 32px;
                ;
            }
        }

        .sns_section {
            margin-top: 8vh;
            display: flex;
            gap: 20px;

            img {
                width: 30px;
            }
        }


    }
}

.contents_wrap {
    &.artist_fixed{
        position: fixed;
        z-index: 200;
    }
    &>.main {
        .sub_copy{
            position: relative;
            z-index: 1;
           
        }
        .more_bt_section{
            position: relative;
            z-index: 0;
            .more_bt{
                margin-top:-70px;
                margin-left:-30px;
            }
        };
        &.m1 {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top:3vw;
            background-size: cover;
            flex-direction: column;
            background-position: center center;
            color:#fff;
           
            .main_title{
                font-size:90px;
                letter-spacing: 0.02em;
                display:flex;
                align-items: flex-end;
                font-weight: 800;
                gap:12px;
                .big_t{
                    font-size:150px;
                    line-height: 150px;;
                    
                }
            }
            .main_desc{
                text-align: center;
                margin-top:40px;
                font-weight: 700;
                font-size: 30px;
                line-height: 42px;
                width:700px;
                
            }
            .bt_section{
                margin-top:-15px;
                .more_bt{
                    color:#fff;
                }
            }
        }

        &.m2 {
            margin-top:220px;
            min-height:60vw;

            .main_img{
               position: relative;
                .swiper-wrapper{
                    line-height: 0px;
                    
                }
                .swiper{
                    position: absolute;
                    width:38vw;
                    height:38vw;
                    margin-left:-29vw;
                    left:50%;
                    top:0px;
                    line-height: 0px;
                    border-radius: 50%;
                    background-color:#8B00ff;
                  
                    .swiper-slide{
                        height:38vw;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    img{
                        width:101%;
                        margin-top:-0.5%;
                        margin-left:-0.5%;
                        visibility: initial !important;
                        opacity: 1 !important;
                    }
                    
                }
                
                .iok_img {
                    position: relative;
    
                    img {
                        width: 100%;
                    }
                }
            }
           

            .sns_section {
                position: absolute;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #fff;
                right: 11%;
                top: 50%;
                text-transform: uppercase;
                margin-top: -90px;

                li {
                    margin-bottom: 12px;
                }

            }

            .main_copy {
                width: 1050px;
                margin: 120px auto 0px;
                display:flex;
                flex-direction: column;
                align-items: center;

                .section_title {
                    font-weight: 800;
                    font-size: 4.2vw;
                    line-height:4.2vw;
                    letter-spacing: 0.025em;
                }

                .sub_copy_section {
                    margin-top: 30px;
                    font-size: 1.56vw;
                    line-height: 2.2vw;
                    font-weight:700;
                    text-align: center;

                   

                    .more_bt_section {
                        margin-top: 50px;
                        display:flex;
                        justify-content: center;
                    }
                }
            }
        }

        &.m3 {
            margin-top: 250px;
            padding: 0px 140px 300px;


            .business_items {
                .bs_item {
                    display: flex;
                    align-items: center;
                    gap: 60px;
                    position: relative;
                    &:hover{
                        .bs_title{
                            color:#8B00ff;
                        }
                        .bs_subtitle{
                            transform: translateX(20px);
                        }
                        .bs_img{
                            opacity: 1;
                        }
                    }

                    .bs_num {
                        font-weight: 600;
                        font-size: 1.25vw;
                    }

                    .bs_title {
                        font-weight: 800;
                        font-size: 7vw;

                        letter-spacing: 0.025em;
                       
                    }

                    .bs_subtitle {
                        font-size: 1.2vw;
                        font-weight: 500;
                        transition-duration: 0.3s;

                        img {
                            margin-left: 20px;
                            vertical-align: middle;
                            margin-top: -0.2vw;
                            width: 18px;
                        }
                    }
                    .bs_img{
                        transition-duration: 0.3s;
                        opacity: 0;
                        position: absolute;
                        right:-140px;
                        top:50%;
                        margin-top:-4.6vw;
                        img{
                            width:8vw;
                        }
                    }
                }
            }
        }

        &.m4 {
            margin-top: 300px;
            padding: 0px 80px;
            .main_img {
                img {
                    width: 100%;
                }
            }

            .main_copy {
                width: calc(40% + 600px);
                margin: -105px auto 0px;

                .section_title {
                    font-weight: 800;
                    font-size: 120px;
                    line-height: 120px;
                    letter-spacing: 0.025em;

                    .white_t {
                        color: #fff;
                    }
                }

                .sub_copy_section {
                    margin-top: 30px;
                    padding-left:500px;
                    font-size: 30px;
                    line-height: 42px;
                    font-weight:700;

                    .empty_t {
                        display: inline-block;
                        width: 130px;
                    }

                    .more_bt_section {
                        margin-top: 50px;
                    }
                }
            }
        }

        &.m5 {
            margin-top: 300px;
            padding: 0px 80px 300px;

            

            .news_header {
                display: flex;
                width: 86%;
                margin: 0px auto;
                justify-content: space-between;
                align-items: center;


                .section_title {
                    font-size: 24px;
                    font-weight: 600;
                }

                .link_bt {
                    &:hover{
                        color:#8B00ff;
                        text-decoration: underline;
                        img{
                            display:none;
                        }
                        .hover_img{
                            display:inline;
                        }
                    }
                    .hover_img{
                        display:none;
                        width: 16px;
                        margin-right: 10px;
                        vertical-align: middle;
                        margin-top: -5px;
                    }
                    img {
                        width: 16px;
                        margin-right: 10px;
                        vertical-align: middle;
                        margin-top: -5px;
                    }

                    font-size:18px;
                    font-weight:500;
                }
            }

            .news_items {
                margin-top: 80px;
                border-top: 1px solid #eee;
                a:last-child {
                    .news_item{
                        border-bottom: none;
                    }
                     }


                  
                .news_item {
                    display: flex;
                    gap: 70px;
                    align-items:center;
                    color: #222;
                    padding: 30px 7%;
                    border-bottom: 1px solid #eee;
                    &:hover{
                        .news_date{
                            color:#8B00ff;
                        }
                        .news_title{
                            color:#8B00ff;
                        }
                        .news_img{
                            opacity: 1;
                        }
                    }
                    .news_img{
                        width:300px;
                        height:300px;
                        position: fixed;
                        left:50%;
                        top:50%;
                        margin-left:-150px;
                        margin-top:-150px;
                        pointer-events: none;
                        z-index: 1;
                        opacity: 0;
                        background-size: cover;
                       
                    }

                


                    .news_date {
                        position: relative;
                        z-index: 2;
                        font-size: 16px;
                        color:#888;
                        line-height: 24px;
                        pointer-events: none;
                        font-weight:500;
                        margin-top:-7px;
                        

                    }

                    .news_title {
                        pointer-events: none;;
                        position: relative;
                        z-index: 2;
                        flex:1;
                        overflow:hidden; 
                        text-overflow:ellipsis;
                         white-space:nowrap;
                        margin-top:-0.3vw;

                        font-size:2.5vw;
                        font-weight: 500;
                    }

                }
            }

            .more_bt_section {
                margin-top: 80px;
                display: flex;
                justify-content: center;
                ;
            }
        }
    }

    &>.artist {
        padding-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .section_title {
            font-size: 60px;
            font-weight: 700;
        }

        .type_menu {
            margin-top: 45px;
            display: flex;
            gap: 35px;
        }

        .ar_filter {
            margin-top: 45px;
            display: flex;
            gap: 20px;

            li {
                font-weight: 500;
                font-size: 16px;
                color: #b2b2b2;
                cursor: pointer;
                &:hover{
                    color:#222;
                }
                &.active {
                    color: #8B00ff;
                }
            }
        }

        .artist_items {
            margin-top: 110px;

            &.name_items {
                width:80%;
                padding: 0px 0% 400px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 40px;

                .ar_item {
                    position: relative;
                    font-size: 60px;
                    line-height: 80px;
                    animation: ani_show 1s;
                    
                    cursor: pointer;
                    font-weight: 700;
                    &:hover{
                        color:#8B00ff;
                        .hover_img{
                            opacity: 1;
                        }
                        .ar_name{
                            position: relative;
                            z-index: 5;
                        }
                        
                    }
                    .hover_img{
                        opacity: 0;
                        pointer-events: none;
                        transition-duration: 0.2s;
                        position: absolute;
                        width:360px;
                        height:540px;
                        left:50%;
                        top:50%;
                        margin-left:-160px;
                        margin-top:-250px;
                        z-index: 1;

                        img{
                            width:100%;
                          
                        }
                    }
                    &:last-child,&.last_line{
                        &:after{
                            display:none;
                        }
                    }

                    &:after {
                        position: absolute;
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #8B00ff;
                        top: 35px;
                        right: -25px;
                    }
                }

            }

            &.photo_items {
                padding: 0px 0px 400px;
                display: grid;
                width:100%;
                grid-template-columns: repeat(6, 240px);
                grid-row-gap: 96px;
                justify-content: space-between;
               
                


                .ar_item {
                    
                    width: 240px;
                    height:360px;
                    cursor: pointer;
                    line-height: 0px;;
                    position:relative;
                    transition-duration: 0.3s;
                    font-size:0px;
                    background-color:#f2f2f2;
                    animation: ani_show 1s;
                    
                    
                    &:hover{
                        background-color:#8B00ff;
                        
                    }
                    .ar_name{
                        margin-top:10px;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        font-size:21px;
                        line-height: 32px;
                        .t_en{
                            font-size:20px;
                        }
                    }; 

                    img {
                        width: 240px;
                    }
                }
            }
        }

        .artist_detail {
            position: fixed;
            top: 100vh;
            background-color: #fff;
            width: 100%;
            left: 0px;
            bottom: -100vh;
            overflow-y: auto;
            padding-top: 200px;
            z-index: -10;
            transition-duration: 0.3s;
            opacity: 0;
            pointer-events: none;

            &.active {
                top: 0px;
                bottom: 0px;
                z-index: 1000;
                 opacity: 1;
                pointer-events: initial;
                img{
                    opacity: 1 !important;
                    visibility: initial !important;
                }
            }

            .close_bt {
                position: fixed;
                z-index: 2;
                right: 80px;
                top: 42px;
                cursor: pointer;
                width: 28px;
                height: 30px;
            }

            .ar_main {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                .ar_main_img {
                    width: 80%;
                    min-height: 70vw;

                    img {
                        width: 100%;
                    }
                }

                .ar_deco_img {
                    position: absolute;

                    &.ard1 {
                        left: 0%;
                        top: 10%;

                        img {
                            width: 400px;
                        }
                    }

                    &.ard2 {
                        right: -2%;
                        top: 45%;

                        img {
                            width: 441px;
                        }
                    }
                }

                .ar_main_info {
                    position: absolute;
                    width: 80%;
                    left: 10%;
                    bottom:0px;
                    z-index: 1;

                    .ar_name {
                        font-weight: 800;
                        font-size: 8vw;
                        letter-spacing: 0.025em;
                        color: #8B00FF;

                    }

                    .ar_subinfo {
                        display: flex;
                        flex-direction: column;
                        gap:30px;

                        .ar_profile {
                            .ar_name_kr {
                                font-size: 48px;
                                font-weight: 500;
                            }

                            .ar_birth {
                                font-size: 24px;
                                margin-top: 15px;
                                font-weight: 500;
                            }

                        }

                        .ar_sns {
                            margin-left:-5px;
                            font-size: 18px;
                            font-weight: 500;
                            text-decoration: underline;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: 10px;   
                        }
                    }
                }
            }

            .ar_career {
                margin-top: 150px;
                padding: 0px 80px;
                width: 100%;
                box-sizing: border-box;

                .arc_item {
                    border-top: 1px solid #000;
                    padding: 50px calc(9% - 50px);

                    &.active {
                        .arc_contents {
                            display: flex;
                        }
                    }

                    .arc_header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .arc_title {
                            font-size: 80px;
                            font-weight: 800;
                        }

                        .arc_active_bt {
                            img {
                                width: 30px;
                            }
                        }
                    }

                    .arc_contents {
                        display: none;
                        margin-top: 50px;
                        gap: 60px;

                        .career_items {
                            .ca_title {
                                font-weight: 600;
                                font-size: 42px;
                                border-bottom: 1px solid #ddd;
                                padding-bottom: 20px;
                                margin-bottom: 30px;
                            }

                            flex:1;

                            .ca_item {
                                display: flex;
                                font-size: 21px;
                                line-height: 30px;
                                gap: 20px;
                                margin-bottom: 10px;

                            }
                        }

                    }
                }
            }

            .ar_manager {
                width: 80%;
                margin: 160px auto 200px;

                .arm_name {
                    font-size: 30px;
                    line-height: 42px;
                    ;
                }

                .arm_contact {
                    margin-top: 20px;
                    font-size: 21px;
                    line-height: 32px;
                }
            }

        }
    }

    &>.news {
        padding-top: 200px;
        padding-bottom: 300px;

        .section_title {
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
        }

        .news_items {
            margin-top: 100px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding: 0px 80px;
            grid-gap: 80px 60px;
            min-height: 80vh;

            &::after {
                content: "";
                flex: auto;
            }

            .news_item {
                cursor: pointer;
                animation: ani_show 1s;
                &:hover{
                    .news_img{
                        .hover_circle{
                            opacity: 1;
                        }
                        &:after{
                            content:'';
                            position:absolute;
                            z-index: 1;
                            left:0px;
                            top:0px;
                            bottom:0px;
                            right:0px;
                            background-color: rgba(0,0,0,0.3);
    
                        }
                    }
                    .news_title,.news_date{
                        color:#8B00ff;
                    }
                }   
                .news_img {
                    height:calc((100vw - 160px - 240px)/4);
                    background-size: cover;
                    background-position:center center;
                    position: relative;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                   
                    .hover_circle{
                        position:relative;
                        z-index: 3;
                        width:120px;
                        height:120px;
                        opacity: 0;
                        transition-duration: 0.5s;
                        border-radius: 50%;
                        background-color: #8B00ff;
                        color:#fff;
                        font-size:18px;
                        font-weight:500;
                        display:flex;
                        justify-content: center;
                        align-items: center;;
                    }
                    img {
                        width: 100%;
                    }
                }

                .news_title {
                    margin-top: 12px;
                    font-size: 18px;
                    line-height: 28px;
                }

                .news_date {
                    margin-top: 12px;
                    font-size: 15px;
                    line-height: 24px;
                    color: #888;
                }
            }
        }

        .more_bt_section {
            margin-top: 170px;
            display: flex;
            justify-content: center;

        }
    }

    &>.announcement {
        padding-top: 200px;
        padding-bottom: 300px;

        .section_title {
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
        }
        .dart_container{
            display:flex;
            justify-content: center;
            margin-top:60px;
            &.mobile_view{
                display:none;
            }
            iframe{
                margin:0px auto;
                border:none;
            }
        }
        .search_section{
            margin-top:60px;
            text-align: center;
            input{
                width:850px;
                height:68px;
                border:1px solid #ddd;
                box-sizing: border-box;
                border-radius: 68px;
                font-size:1.1vw;
                font-weight:500;
                padding:0px 30px;
                background-position: right 30px center;
                background-repeat: no-repeat;
            }
        }


        .announcement_items {
            margin-top: 100px;
            padding: 0px 80px;

            .an_item {
                padding: 36px 8%;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #eee;
                align-items: center;
                animation: ani_show 1s;
                    
                &:hover{
                    .an_title,.an_date{
                        color:#8B00ff;
                    }
                    .an_deco{
                        img{
                            display:none;
                        }
                        .hover_img{
                            display:initial;
                        }
                    }
                   
                }
              

                .an_date {
                    width: 150px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #888;
                }
                .an_type{
                    font-size:18px;
                    font-weight:500;
                    color:#b2b2b2;
                    width:57px;
                    height:36px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    border:1px solid #b2b2b2;
                    border-radius: 18px;
                    margin-right:30px;
                    box-sizing: border-box;

                }

                .an_title {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 2.5vw;
                    font-weight: 500;
                }

                .an_deco {
                    transition-duration: 0.3s;
                    .hover_img{
                        display:none;
                    }
                    img {
                        width: 15px;
                    }
                }
            }
        }

        .more_bt_section {
            margin-top: 170px;
            display: flex;
            justify-content: center;

        }
    }

    &>.news_detail,
    &>.announcement_detail {
        padding: 200px 80px 300px;
        margin: 0px auto;
        max-width: 1280px;
        

        &.announcement_detail {
            .detail_title {
                text-align: center;
            }
        }

        .detail_title {
            font-size: 48px;
            font-weight: 60px;
            font-weight: 500;
            text-align: center;
        }

        .detail_contents {
            min-height:50vh;
            margin-top: 100px;
            font-size: 24px;
            line-height: 36px;
            white-space: pre-wrap;
            .file_section{
                margin-top:80px;
                color:#8B00ff;
                a:hover{
                    text-decoration: underline;
                }
            }
           
            .thumbnail_img{
                margin-bottom:20px;
                text-align: center;
            }

            img {
                max-width: 100%;
            }
        }

        .bt_section {
            margin-top: 80px;
        }

        .more_bt_section {
            margin-top: 130px;
            display: flex;
            justify-content: center;
            .more_bt{
             
            }
        }

    }

    &>.contact,
    &>.audition {
        padding-top: 200px;
        padding-bottom: 300px;

        .ani_t_line{
            display:block;
            .section_title {
                font-size: 3vw;
                font-weight: 700;
                text-align: center;
            }
    

        }
        .main_img {
            margin:110px 80px 0px;
            img {
                width: 100%;
            }

        }

        
        .contact_map {
            margin:110px 80px 0px;
            height:550px;
          

        }

      
    }
    .section_container {
        margin-top: 120px;
        padding: 0px 80px;
        display: flex;

        .section_title {
            font-size: 20px;
            font-weight: 500;
            width: 300px;
        }

        .section_contents {
            flex: 1;

            &>.sc_title {
                font-weight: 700;
                font-size: 3vw;
                line-height:4vw;
            }
            &>.sc_subtitle{
                margin-top:24px;
                font-size:1.56vw;
                line-height: 2.2vw;
                font-weight:700;   
            }

            .sc_items {
                margin-top: 100px;
                border-top: 1px solid #222;

                .sc_item {
                    padding: 48px 0px;
                    border-bottom: 1px solid #ddd;
                    display: flex;
                    font-size: 1.56vw;
                    line-height: 2.2vw;


                    .sc_title {
                        width: 180px;
                        font-weight: 700;

                    }

                    .sc_contents {
                        flex: 1;

                        .subinfo {
                            margin-top: 30px;
                            font-size: 1.25vw;
                            line-height: 2vw;
                            color: #666;
                            &.reference_info{
                                color:#222;
                                margin-top:10px;
                                li{
                                    &:before{
                                        background-color: #222;;
                                    }
                                }
                            }

                            li{
                                position: relative;
                                padding-left:15px;
                               &:before{
                                   content:"";
                                   position:absolute;
                                   left:0px;
                                   top:0.9vw;
                                   width:4px;
                                   height:4px;
                                   border-radius: 50%;
                                   background-color:#666;

                               }
                            }
                        }

                        .faq_items {
                            .faq_item {
                                margin-bottom: 50px;
                                cursor: pointer;

                                &.active {
                                    .faq_contents {
                                        display: block;
                                    }
                                }

                                .faq_header {

                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    text-decoration: underline;

                                }

                                .faq_contents {
                                    margin-top: 15px;
                                    display: none;
                                }
                            }

                        }
                    }

                    &:last-child {
                        border-bottom: none;
                        ;
                    }

                    .input_section {
                        margin-top: 70px;
                        .error_msg{
                            font-size:1.4vw;
                            margin-top:10px;
                            color:#FF4D00;
                        }
                        input,
                        textarea {
                            font-size: 1.56vw;
                            line-height: 2.2vw;
                            width: 100%;
                            padding: 25px 0px;

                            border: none;
                            border-bottom: 1px solid #ddd;

                        }
                        textarea{
                            margin-top:20px;
                            padding:10px 0px;
                            min-height: 200px;
                        }



                    }

                    .bt_section {
                        margin-top: 40px;
                    }
                }

            }
        }
    }
    &>.about{
     
        .ani_t_line{
            display:block;
            .section_title {
                font-size: 3vw;
                font-weight: 700;
                text-align: center;
            }
        }
        
        .section_container{
            padding:0px;
        }
        &.a1{
            padding:200px 80px 0px;
            .main_img{
                margin-top:110px;
                img{
                    width:100%;
                }
            }
            .section_container{
                
                .section_contents{
                    font-size:3vw;
                    line-height: 4vw;
                    font-weight:700;
                }
            }
        }
        &.a2{
            margin-top:20vw;
            padding:0px 80px;
            position: relative;
            .section_container{
                height:900px;
                 .bs_deco_t_wrap{
                        position: absolute;
                        overflow: hidden;
                        height:17vw;
                        top:50%;
                        left:0px;
                        width:100%;
                        margin-top:-7.5vw;
                        .bs_deco_t{
                            position: absolute;
                            z-index: 1;
                            font-size:15vw;
                            font-weight:800;
                            letter-spacing: 0.025em;
                            color:#8B00ff;
                            top:0px;
                            left:100%;
                            width:600vw;
                            margin-left:-100vw;
                            
                            text-align: center;
                        }
                    }
                .section_contents{
                    position: absolute;
                    top:0px;
                    left:50%;
                    margin-left:-450px;
                  
                  
                    
                    .business_container{
                        width:900px;
                        height:900px;
                        position: relative;
                        z-index: 3;
                        .bs_deco_tri{
                            position: absolute;
                            top:-25px;
                            left:50%;
                            margin-left:-24px;
                            z-index: 2;
                            img{
                                width:48px;
                            }
                        }
                        .bs_img{
                            position: absolute;
                            z-index: 1;
                            left:0px;
                            top:0px;
                            border-radius: 50%;
                            width:900px;
                            height:900px;
                            overflow: hidden;
                            
                            box-shadow: 0px 50px 50px rgba(0,0,0,0.1);
                            
                            img{
                                width:100%;
                            }
                            
                        }
                        .bs_deco_circle{
                            position:absolute;
                            z-index: 2;
                            left:45px;
                            top:45px;
                            width:810px;
                            height:810px;
                            border-radius: 50%;
                            border:1px solid #fff;
                            box-sizing: border-box;
                            opacity: 0.3;
                        }
                        .bs_bt_items{
                            position: relative;
                            z-index: 3;
                            height:900px;
                            transition-duration: 0.5s;
                            li{
                            position:absolute;
                            left:50%;
                            top:50%;
                            width:200px;
                            margin-left:-100px;
                            margin-top:-15px;
                            line-height: 1;
                                text-align: center;
                                font-size:30px;
                                color:#fff;
                                font-weight:700;
                                transform-origin: center center;
                                &:nth-child(1){
                                    transform:rotate(0deg) translateY(-403px);
                                }
                                &:nth-child(2){
                                    transform:  rotate(60deg) translateY(-403px);
                                }
                                &:nth-child(3){
                                    transform:  rotate(120deg)  translateY(-403px);
                                }
                                &:nth-child(4){
                                    transform:  rotate(180deg)  translateY(-403px);
                                }
                                &:nth-child(5){
                                    transform:  rotate(240deg) translateY(-403px);
                                }
                                &:nth-child(6){
                                    transform:  rotate(300deg) translateY(-403px);
                                }
                                


                            }
                        }

                    }
                }
            }
        }
        &.a3{
            display:none;
            background-size: cover;
            height:1000px;  
            color:#fff;
            padding:100px 0px;
            box-sizing: border-box;
            .section_container{
                padding:0px 80px;
                .section_contents{
                    font-size:30px;
                    line-height: 42px;
                    padding-right:18%;
                    word-break: keep-all;
                }

            }
            .timeline_items{
                position: relative;
                
                margin-top:100px;
                .tl_deco_line{
                    height:2px;
                    width:100%;
                    position: absolute;
                    top:50%;
                    left:0px;
                    background-color:#fff;
                }
                .tl_section{
                    position: relative;
                    height:500px;
                    .tl_item{
                        position: absolute;
                        top:50%;
                        margin-top:-130px;
                        left:50%;
                        display:flex;
                        flex-direction: column-reverse;
                        
                        .tl_deco_circle{
                            width:24px;
                            height:24px;
                            border-radius: 50%;
                            background-color:#fff;
                        }
                        .tl_title{
                            margin-top:16px;
                            font-weight:700;
                            font-size:30px;
                            margin-bottom:36px;
                        }
                        .tl_date{
                            font-weight:600;
                            font-size:24px;
                        }
                        &:nth-child(1){
                            left:20%;

                        }
                        &:nth-child(2){
                            left:45%;
                            flex-direction: column;
                            margin-top:-12px;
                            .tl_t{
                                margin-top:36px;
                            }
                        }
                        &:nth-child(3){
                            left:70%;
                        }
                    }
                }
            }
          
        }
        &.a4{
            margin-top:360px;
            padding:0px 80px 100px;
            .section_container{
                .section_contents{
                    border-top:1px solid #222;
                    .info_item{
                        display:flex;
                        font-size:1.56vw;
                        line-height: 2.2vw;
                        padding:36px 0px;
                        border-bottom:1px solid #ddd;
                        &:last-child{
                            border-bottom: none;
                        }
                        .info_title{
                            width:15%;
                            font-weight:700;
                        }
                        .info_contents{
                            flex:1;
                        }
                    }
                }
            }
        }
        &.a5{
            margin-top:60px;
            padding:0px 80px 300px;
            .section_container{
                .section_contents{
                    border-top:1px solid #222;
                    font-size:1.56vw;
                    line-height: 2.2vw;
					padding:24px 0;
                    p{
						margin-top:42px;
						text-align:right;
                    }
                }
            }
        }
    }
    &>.what{
        &.w1{
            padding:200px 80px 0px;
            
            .ani_t_line{
                display:block;
                .section_title {
                    font-size: 3vw;
                    font-weight: 700;
                    text-align: center;
                }
            }
            .cate_menu{
                position: fixed;
                top:430px;
                z-index: 5;
                li{
                    font-size:20px;
                    font-weight:500;
                    margin-bottom:20px;
                    color:#b2b2b2;
                    &:hover{
                        color:#222;
                    }
                    
                    &.active{
                        color:#8B00ff;
                    }
                }
            }
        }
       
        &.w2{
            margin-top:150px;
            .business_section{
                padding:0px;
                margin-bottom:300px;
                .bs_bt_section{
                    margin-top:80px;
                }
                &.bs3{
                    .bs_desc{
                        margin-left:19%;
                    }
                    .swiper {
                        width: 100%;
                        position: relative;
                        .ar_name{
                            position: absolute;
                            bottom:68px;
                            font-size:48px;
                            font-weight:500;
                            left:0px;
                            text-align: center;
                            right:0px;
                            color:#fff;


                        }
                      }
                }
                &.bs4{
                    .swiper {
                        width: 100%;
                    }
                    .bs_bt_section{
                        padding-left:19%;
                    }
                }
                &.bs5{
                    .bs5_img{
                        padding-left:19%;
                        margin-top:150px;
                        img{
                            width:720px;
                        }
                    }
                    .bs_bt_section{
                        padding-left:19%;
                    }
                }
                &.bs6{
                    .bs6_img{
                        padding-left:19%;
                        margin-top:150px;
                        img{
                            width:720px;
                        }
                    }
                    .bs_bt_section{
                        padding-left:19%;
                    }
                }
                .bs_title{
                    padding-left:19%;
                    font-size:1.56vw;
                    font-weight:700;
                    line-height: 2.2vw;
                    position: relative;
                  
                }
                .bs_subtitle{
                    margin-top:5vw;
                    padding-left:19%;
                    font-size:2.4vw;
                    font-weight:500;
                    line-height: 3vw;
                }
                .bs_maincopy{
                    margin-top:5vw;
                    padding-left:19%;
                    font-size:3vw;
                    font-weight:700;
                    line-height: 4vw;
                    padding-right:10%;
                }
                .bs_desc{
                    padding-left:0%;
                    margin-top:100px;
                    width:820px;
                    border-top:1px solid #222;
                    padding-top:30px;
                    font-size:22px;
                    line-height: 46px;
                    font-weight:400;

                }
                .poster_container{
                    margin-top:150px;

                    height:calc(100px + 21vw);
                    position: relative;
                    margin-left:19%;
                    box-sizing: border-box;
                    width:81%;
                    &:hover{
                        .navi_bt{
                            img{
                                opacity: 0;
                            }
                            .active{
                                opacity: 1;
                            }
                        }
                    }
                    .navi_bt{
                        position: absolute;
                        z-index: 5;
                        top:9vw;
                        &.hide_bt{
                            opacity: 0;
                        }
                        
                        img{
                            position: absolute;
                            right:0px;
                            top:0px;
                            transition-duration: 0.3s;
                            width:80px;
                            &.active{
                                opacity: 0;
                            }
                        }
                        
                        &.prev_bt{
                            left:100px;
                            img{
                                transform: rotate(180deg);
                            }

                        }
                        &.next_bt{
                            right:80px;
                        }
                    }
                    .poster_items{
                        display:flex;
                        user-select: none;
                        
                        .p_item{
                            // width:320px;
                            img{
                                width:100%;
                                pointer-events: none;
                            }
                            .p_title{
                                font-size:18px;
                                margin-top:20px;
                                text-align: center;
                            }
    
                        }
                    }
                }
                .main_img{
                    margin-top:150px;
                    width:35vw;
                    height:35vw;
                    margin-left:19%;
                    line-height: 0px;
                    border-radius: 50%;
                    background-color:#8B00ff;
                  
                    .swiper-wrapper{
                        line-height: 0px;
                    }
                    .swiper{
                        width:100%;
                        .swiper-slide{
                            height:35vw;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        
                    }
                    img{
                        width:101%;
                        margin-top:-0.5%;
                        margin-left:-0.5%;
                        visibility: initial !important;
                        opacity: 1 !important;
                    }
                }
                .main_sl{
                    margin-top:150px;
                    padding:0px 19%;
                    img{
                        width:100%;
                    }
                }
                
                .bs_magazine_items{
                    margin-top:150px;
                    padding-left:19%;
                    display:flex;
                    gap:60px;
                    position: relative;
                    .bsm_deco{
                        position: absolute;
                        top:245px;
                        left:calc(19% + 485px);
                        z-index: 5;
                    }
                    .bsm_deco_m{
                        display:none;
                    }
                    .bsm_item{
                        width:540px;
                        .bsm_img{
                            
                            img{
                                width:100%;
                            }
                        }
                        .bsm_desc{
                            margin-top:70px;
                            border-top:1px solid #222;
                            padding-top:36px;
                            font-size:24px;
                            line-height: 36px;
                        }
                    }
                }
             
            }

        }
    }
    &>.policy {
        padding-top: 200px;
        padding-bottom: 300px;

        .section_title {
            font-size: 60px;
            font-weight: 700;
            text-align: center;
        }
        .poli_section{
            margin-top:120px;
            padding:0px 80px;
            &>.poli_title{
                font-size:48px;
                line-height: 60px;
                font-weight:500;
                text-align: center;
                border-bottom:1px solid #222;
                padding-bottom:48px;
            }
            .poli_items{
            	.poli_subtitle{
					font-size:32px;
					text-align:center;
					padding:22px 0;
					border-bottom:1px solid #ddd;
				}
            	.poli_item{
            	    padding:36px 7%;
            	    display:flex;
            	    border-bottom:1px solid #ddd;
            	    &:last-child{
            	        border-bottom:none;
            	    }
            	    .poli_title{
            	        width:300px;
            	        font-weight:500;
            	        font-size:21px;
            	        line-height: 32px;;
            	    }
            	    .poli_contents{
            	        flex:1;
            	        font-size:24px;
            	        line-height: 36px;
            	        .sub_info{
            	            margin:15px 0px;
            	            font-size:21px;
            	            line-height: 32px;
            	        }
            	    }
            	}
			}
        }
    }
}

.footer {
    position: relative;
    padding: 56px 0px;
    margin: 0px 80px;
    box-sizing: border-box;
    height: 400px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #222;
    

    .ft_left_section {
        flex: 1;

        .ft_address {
            font-size: 18px;
            line-height: 27px;
        }

        .ft_subscribe {
            margin-top: 68px;

            .fts_title {
                font-size: 24px;
                font-weight: 600;
            }

            .fts_email {
                margin-top: 30px;

                input[type=text] {
                    font-size: 30px;
                    border: none;
                    line-height: 50px;
                    border-bottom: 1px solid #B2B2B2;
                }
            }
        }
		.ft_relative{
            margin-top:8px;
            font-size: 12px;
		}
        .ft_sub_menu{
            margin-top:30px;
            font-size:18px;
            
        }

        .ft_copyright {
            margin-top: 30px;
            font-size: 16px;
            color: #888;
        }
    }

    .ft_menu_section {
        width: 15%;

        ul li {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 12px;
            font-weight: 600;
            cursor: pointer;

        }
    }

    .ft_right_section {
        width: 320px;
        font-size: 18px;

        .ft_sns_section {
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .ft_bottom_info_mobile{
        display:none;
    }
    .ft_top_bt {
        position: absolute;
        right: 0px;
        bottom: 60px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
            &:after{
                opacity: 1;
            }
        }
        &:after{
            opacity: 0;
            transition-duration: 0.3s;
            content: '';
            width:60px;
            height:60px;
            position: absolute;
            left:50%;
            top:50%;
            margin-left:-30px;
            margin-top:-30px;
            background-color: #f3d42d;
            border-radius: 50%;
            z-index: -1;
        }
    }

}

.default_bt {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    &.more_bt {
        font-size: 18px;
        font-weight: 500;
        color: #222;
        position: relative;
        width:160px;
        height:160px;
        //background-color: #f3d42d;
        border-radius: 50%;
        transition-duration: 0.3s;
        &:hover{
            color:#fff;
            background-color: #8B00FF;
        }
        
    }

    &.submit_bt {
        padding: 0px 20px;
        height: 38px;
        font-size: 18px;
        font-weight: 400;
        background-color: #8B00FF;
        border-radius: 48px;
        color: #fff;
        transition-duration: 0.3s;
        box-sizing: border-box;
        
        &:hover{
            box-sizing: border-box;
            background-color: #fff;
            border:1px solid #8B00ff;
            color:#8B00ff;
        }
       
    }

}

@media only screen and (max-width: 1600px) and (min-width:1000px){
    .contents_wrap{
        .main{
            &.m3{
                .business_items{
                    .bs_item{
                        gap:50px;
                        .bs_title{
                            font-size:6.6vw;
                        }
                    }
                }
            }
            &.m4{
                .main_copy{
                    .sub_copy_section{
                        padding-left: 300px;;
                    }
                }
            }
        }
        &>.news{
            .news_items{
                grid-gap:60px 40px;
               
            }
        }
        .artist{
            .artist_items{
                &.photo_items{
                    grid-template-columns: repeat(5, 240px);
                }
                
            }
        }
       
    }
   
}

@media only screen and (max-width: 900px) {
    * {
       
        cursor:initial !important;
    }
    .mouse_pointer{
        display:none;
    }
    .mobile_view {
        display: initial;
    }
    .en_languege{
        
        &.contents_wrap{
            .artist{
                .artist_items{
                    &.name_items{
                        flex-wrap: initial;
                        flex-direction: column;
                        row-gap: 15px;
                        .ar_item{
                            font-size:7vw;
                            text-align: center;
                            &:after{
                                display:none;
                            }
                        }
                    }
                }
            }
        }
    }

    .header {
        left: 16px;
        right: 16px;
        top: 24px;
        &.scroll_up{
            &.menu_active{
                top: 24px;
            }
        }
        
        .logo_section {
            img {
                width: 50px;
            }
        }

        .menu_right_section {
            gap: 36px;

            &.active {

                .mn_ic {
                    .mn_bar {

                        &:nth-child(1) {
                            transform: translateY(8px) rotate(45deg);
                        }

                        &:nth-child(3) {
                            width: 100%;
                            transform: translateY(-8px) rotate(-45deg);
                        }


                    }
                }
            }

            .mn_language {
                width: 52px;
                height: 24px;
                font-size: 13px;
                line-height: 22px;
                &:hover{
                    background-color:inherit;
                    color:inherit;
                }
            }

            .mn_ic {
                width: 22px;

                .mn_bar {
                    height: 2px;

                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }

        .menu_pop {
            padding: 100px 16px;

            .mnp_menu {
                li {
                    font-size: 9vw;
                    line-height: 11vw;
                    border-bottom: none;
                    ;

                    img {
                        width: 20px;
                    }
                }
            }

            .mnp_submenu {
                margin-top: 36px;

                li {
                    font-size: 30px;
                    line-height: 32px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .contents_wrap {
        &>.main {
            &.m1 {
                padding-top: 0px;
                overflow: hidden;
                .main_title{
                    flex-direction: column;
                    align-items: center;
                    font-size:36px;
                    line-height: 36px;
                    gap:6px;
                    .big_t{
                        font-size:60px;
                        line-height: 60px;
                    }
                }
                .main_desc{
                    margin-top:40px;
                    font-size:18px;
                    line-height: 27px;
                    padding:0px 16px;
                    box-sizing: border-box;
                    width:100%;

                }

            }

            &.m2 {
                overflow: hidden;
                .main_img{
                    position: relative;
                    width: 120vw;
                    height: 120vw;
                    margin-left: -10vw;

                    .swiper {
                        width: 120vw;
                        height: 120vw;
                        margin-left: initial;
                        left:initial;
                        .swiper-slide {
                            width: 120vw;
                            height: 120vw;
                        }
                    }

                    img {
                        width: 101%;
                    }
                    .iok_img{
                        display:none;
                    }
                }
                .main_copy {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 40px;
                    box-sizing: border-box;

                    .section_title {
                        font-size: 13.5vw;
                        line-height: 14vw;
                    }

                    .sub_copy_section {
                        padding-left: 16px;
                        padding-right: 16px;
                        margin-top: 20px;

                        .sub_copy {
                            font-size: 18px;
                            line-height: 24px;
                            word-break: keep-all;

                            .empty_t {
                                width: 80px;
                            }

                            br {
                                display: none;
                            }
                        }

                        .more_bt_section {
                            margin-top: 14vw;
                            .more_bt {
                                margin-top: -17vw;
                                margin-left: 0%;
                            }
                        }
                    }

                }
            }

            &.m3 {
                margin-top: 180px;
                padding: 0px 16px 100px;
                overflow: hidden;
                .business_items {

                    .bs_item {
                        gap: 8px 20px;
                        margin-bottom: 30px;
                        flex-wrap: wrap;

                        .bs_num {
                            font-size: 15px;
                        }

                        .bs_title {
                            font-size: 36px;
                        }

                        .bs_subtitle {
                            width: 100%;
                            font-size: 15px;
                            line-height: 21px;
                            margin-left: 37px;

                            img {
                                margin-top: -3px;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }

            &.m5 {
                margin-top: 200px;
                padding: 0px 16px 100px;

                .news_header {
                    width: 100%;

                    .section_title {
                        font-size: 18px;
                    }

                    .link_bt {
                        font-size: 13px;

                        img {
                            width: 9px;
                            margin-top: -2px;
                        }
                    }
                }

                .news_items {
                    margin-top: 36px;
                  
                    .news_item {
                        padding: 24px 0px;
                        flex-direction: column;
                        gap: 10px;
                        margin-top: 0px;
                        align-items: flex-start;
                        .news_img{
                            display:none;
                        }
                        .news_date {
                            font-size: 14px;
                            color: #888;
                            margin-top: 0px;
                        }

                        .news_title {
                            font-size: 24px;
                            line-height: 32px;
                            text-overflow: initial;
                            overflow: initial;
                            white-space: initial;
                            margin-top: 0px;
                        }
                    }
                }

            }
        }

        &>.about {
            &.a1 {
                padding: 108px 0px 0px;

                .section_title {
                    font-size: 36px;
                }

                .main_img {
                    margin-top: 48px;
                }

                .section_container {
                    padding: 0px 16px;

                    .section_contents {
                        font-size: 28px;
                        line-height: 42px;
                        padding-right: 0px;
                        br{
                            display:none;
                        }
                    }
                }
            }

            .section_container {
                flex-direction: column;

                .section_title {
                    font-size: 15px;
                }

            }

            &.a2 {
                margin-top: 90px;
                padding: 0px 16px 50px;
                overflow: hidden;


                .section_container {
                    height: calc(100vw + 32px);

                    .section_contents {
                        position: relative;
                        left: initial;
                        top: initial;
                        margin-left: 0px;
                        margin-top: 40px;

                    

                        .business_container {
                            width: calc(100vw - 32px);
                            height: calc(100vw - 32px);

                            .bs_deco_tri {
                                margin-left: -12px;
                                top: -12px;

                                img {
                                    width: 24px;

                                }
                            }

                            .bs_img {
                                width: calc(100vw - 32px);
                                height: calc(100vw - 32px);
                                box-shadow: 0px 20px 20px rgba(0,0,0,0.1);
                            }

                            .bs_bt_items {
                                height: calc(100vw - 32px);
                            }

                            .bs_deco_circle {
                                width: calc(100vw - 60px);
                                height: calc(100vw - 60px);
                                left: 14px;
                                top: 14px;
                            }

                            .bs_bt_items {
                                
                                li {
                                    font-size: 12px;
                                    width: 100px;
                                    margin-left: -50px;
                                    margin-top: -6px;

                                    &:nth-child(1) {
                                        transform: rotate(0deg) translateY(-41vw);
                                    }

                                    &:nth-child(2) {
                                        transform: rotate(60deg) translateY(-41vw);
                                    }

                                    &:nth-child(3) {
                                        transform: rotate(120deg) translateY(-41vw);
                                    }

                                    &:nth-child(4) {
                                        transform: rotate(180deg) translateY(-41vw);
                                    }

                                    &:nth-child(5) {
                                        transform: rotate(240deg) translateY(-41vw);
                                    }

                                    &:nth-child(6) {
                                        transform: rotate(300deg) translateY(-41vw);
                                    }
                                }
                            }
                        }

                        .bs_deco_t {
                            display: none;
                        }
                    }
                    .bs_deco_t_wrap{
                           margin-top:-2vw;       
                    }

                }

            }

            &.a4 {
                margin-top: 100px;
                padding: 0px 16px 100px;

                .section_container {
                    .section_contents {
                        margin-top: 36px;

                        .info_item {
                            padding: 24px 0px;
                            font-size: 18px;
                            line-height: 27px;
                            flex-direction: column;
                            gap: 24px;

                            .info_title {
                                width: 100px;
                                br{
                                    display:none;
                                }
                            }

                            .info_contents {
                                flex: 1;
                            }

                        }
                    }
                }
			}

			&.a5 {
                margin-top: 0px;
                padding: 0px 16px 100px;

                .section_container {
                    .section_contents {
                        margin-top: 36px;

                        font-size: 18px;
                        line-height: 27px;
                    	p{
							margin-top:42px;
							text-align:right;
                    	}
                    }
                }
            }
        }

        &>.what {
            &.w1 {
                padding: 108px 0px 0px;

                .ani_t_line {
                    .section_title {
                        font-size: 36px;
                    }
                }

                .cate_menu {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 190px;
                    padding: 0px 16px;
                    display:flex;
                    transition-duration: 0.2s;
                    overflow: scroll;
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                    &::-webkit-scrollbar {
                        display: none; /* Chrome, Safari, Opera*/
                    }
                    &.fixed{
                        position: fixed;
                        top:75px;
                        &.scroll_up{
                            top:26px;
                        }
                    }

                    li {
                        display:inline-block;
                        white-space: nowrap;
                        width:100%;
                        margin-right:15px;
                        font-size: 15px;
                        &:hover{
                            color:inherit;;
                        }
                    }
                }
            }

            &.w2 {
                .business_section {
                    margin-bottom: 140px;
                    overflow-x: hidden;

                    .bs_title {
                        padding-left: 16px;
                        font-size: 18px;
                        line-height: 24px;
                    }

                    .bs_subtitle {
                        margin-top: 36px;
                        padding-left: 16px;
                        font-size: 24px;
                        line-height: 32px;
                    }

                    .bs_maincopy {
                        margin-top: 36px;
                        padding-left: 16px;
                        font-size: 28px;
                        line-height: 42px;
                        padding-right: 16px;

                        br {
                            display: none;
                        }
                        .bs_desc{
                            br {
                                display: block;
                            }
                        }
                    }

                    .main_img {
                        width: calc(100% + 32px);
                        padding-left: 0px;
                        margin-left: -16px;
                        margin-top: 80px;
                        font-size: 0px;
                        line-height: 0px;
                    }

                    .bs_desc {
                        width: 100%;
                        padding-top: 24px;
                        font-size: 16px;
                        line-height: 24px;
                        padding-left: 0px;
                        margin-top: 80px;
                    }

                    .bs_bt_section {
                        margin-top: 40px;
                    }

                    .main_sl {
                        margin-top: 80px;
                        padding: 0px;
                    }

                    .poster_container {
                        padding-left: 16px;
                        width:100%;
                        margin-top: 80px;
                        height:90vw;
                        margin-bottom:0px;
                        margin-left:0px;
                        .navi_bt{
                            top:29vw;
                            &.prev_bt{
                                left:70px;
                            }
                            &.next_bt{
                                right:16px;
                            
                            }
                            img{
                                opacity: 0 !important;
                                &.active{opacity: 1 !important;}
                                width:40px;
                            }
                        }

                        .poster_items {
                            gap: 0px;

                            .p_item {
                                width: 180px;

                                .p_title {
                                    margin-top: 10px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    &.bs2 {
                        .main_img {
                            width: 120vw;
                            height: 120vw;
                            margin-left: -10vw;

                            .swiper {
                                .swiper-slide {
                                    width: 120vw;
                                    height: 120vw;
                                }
                            }

                            img {
                                width: 101%;
                            }
                        }
                    }

                    &.bs3 {
                        .bs_desc {
                            margin-top: 80px;
                            margin-left: 16px;
                            width: calc(100% - 32px);
                        }
                        .swiper{
                            .ar_name{
                                font-size:24px;
                            }
                        }

                    }

                    &.bs4 {
                        .bs_bt_section{
                            padding-left:16px;
                        }
                        .bs_magazine_items {
                            margin-top: 80px;
                            flex-direction: column;
                            padding-left: 0px;
                            gap: 36px;

                            .bsm_deco {
                                display: none;
                            }

                            .bsm_deco_m {
                                display: block;
                                text-align: center;

                                img {
                                    width: 65px;
                                }
                            }

                            .bsm_item {
                                width: 100%;

                                .bsm_desc {
                                    margin-top: 36px;
                                    padding-top: 24px;
                                    margin-left: 16px;
                                    width: calc(100% - 32px);
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                    &.bs5{
                        .bs5_img{
                            margin-top:80px;
                            padding:0px 16px;
                            img{
                                width:100%;
                            }
                        }
                        .bs_bt_section{
                            padding-left:16px;
                        }
                    }
                    &.bs6{
                        .bs6_img{
                            margin-top:80px;
                            padding:0px 16px;
                            img{
                                width:100%;
                            }
                        }
                        .bs_bt_section{
                            padding-left:16px;
                        }
                    }
                }

            }

        }

        &>.artist {

            padding: 108px 0px 0px;

            .section_title {
                font-size: 36px;
            }

            .type_menu {
                margin-top: 24px;
            }

            .ar_filter {
                margin-top: 24px;

                li {
                    font-size: 15px;
                }
            }

            .artist_items {
                margin-top: 90px;

                &.photo_items {
                    grid-template-columns: repeat(3, 110px);
                    grid-gap: 5vw;
                    grid-row-gap: 40px;

                    .ar_item {
                        width: 110px;
                        height: 165px;

                        img {
                            width: 110px;
                        }

                        .ar_name {
                            margin-top:5px;
                            font-size:14px;
                            line-height: 21px;
                            .t_en{
                                font-size:11px;
                                line-height: 18px;
                            }
                        }
                    }
                }

                &.name_items {
                    padding: 0px 10% 400px;
                    gap: 10px 40px;

                    .ar_item {
                        font-size: 24px;
                        line-height: 42px;

                        .hover_img {
                            display: none;
                        }

                        
                        &:last-child {
                            &:after {
                                content: initial;
                            }
                        }

                        &:after {
                            top: 19px;
                            width: 4px;
                            height: 4px;
                            right: -22px;
                        }
                    }
                }
            }

            .artist_detail {
                padding-top: 80px;

                .close_bt {
                    right: 16px;
                    top: 20px;
                    width: 16px;

                    img {
                        width: 100%;
                    }

                }

                .ar_main {
                    .ar_main_img {
                        width: 100%;
                        overflow: hidden;

                        img {
                            width: 110%;
                            margin-left: -5%;
                        }
                    }

                    .ar_deco_img {
                        display: none;
                    }

                    .ar_main_info {
                        position: relative;
                        left: initial;
                        bottom: initial;
                        width: 100%;
                        padding: 0px 16px;
                        bottom:60px;
                        box-sizing: border-box;

                        .ar_name {
                            font-size: 42px;
                            line-height: 42px;
                        }

                        .ar_subinfo {
                            margin-top: 12px;

                            .ar_profile {
                                .ar_name_kr {
                                    font-size: 24px;
                                }

                                .ar_birth {
                                    font-size: 15px;
                                }
                            }

                            .ar_sns {
                                line-height: 0px;

                                img {
                                    width: 32px;
                                }
                            }
                        }
                    }
                }

                .ar_career {
                    padding: 0px;
                    margin-top:50px;

                    .arc_item {
                        padding: 20px 16px;

                        .arc_header {

                            .arc_title {
                                font-size: 36px;
                            }
                        }

                        .arc_contents {
                            flex-direction: column;
                            gap: 42px;

                            .career_items {
                                .ca_title {
                                    font-size: 24px;
                                    padding-bottom: 16px;
                                }

                                .ca_item {
                                    font-size: 15px;
                                    margin-bottom: 5px;
                                    gap: 15px;
                                }
                            }
                        }
                    }
                }

                .ar_manager {
                    padding: 70px 16px 100px;
                    margin: 0px;

                    .arm_name {
                        font-size: 18px;
                    }

                    .arm_contact {
                        font-size: 15px;
                        line-height: 21px;
                    }
                }
            }


        }

        &>.audition,
        &>.contact {
            padding: 108px 0px;

            .ani_t_line {
                .section_title {
                    font-size: 36px;
                }
            }

            .main_img {
                padding: 0px;
                margin: 48px 0px 0px;
            }

            .contact_map {
                padding: 0px;
                margin: 48px 0px 0px;
                height: 300px;
            }

            &.contact {
                .section_container {
                    .section_contents {
                        .sc_items {
                            .sc_item {
                                .sc_contents {
                                    .input_section {
                                        margin-left: 0px;
                                    }

                                    .bt_section {
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &>.news {

            padding: 108px 0px 0px;

            .section_title {
                font-size: 36px;
            }

            .news_items {
                margin-top: 48px;
                padding: 0px 16px;
                grid-template-columns: 1fr;


                .news_item {
                    .news_img{
                        height:calc(100vw - 36px);

                    }
                    .news_title {
                        font-size: 18px;
                        line-height: 27px;
                    }

                    .nes_date {
                        margin-top: 24px;
                        font-size: 15px;
                        line-height: 21px;
                    }
                }
            }

            .more_bt_section {
                margin-top: 50px;
                padding-bottom: 100px;
            }
        }

        &>.news_detail {

            padding: 108px 16px 0px;

            .detail_title {
                font-size: 24px;
                line-height: 32px;
            
            }

            .detail_contents {
                margin-top:48px;
                font-size: 16px;
                line-height: 24px;
                .file_section{
                    margin-top:40px;
                }
                
                .thumbnail_img{
                    margin-bottom: 10px;;
                }

            }

            .bt_section {
                margin-top: 50px;
            }

            .more_bt_section {
                margin-top: 50px;
                padding-bottom: 100px;
            }
        }

        &>.announcement {

            padding: 108px 0px;

            .section_title {
                font-size: 36px;
            }

            .search_section {
                margin-top: 36px;
                padding: 0px 16px;

                input {
                    width: 100%;
                    ;
                    height: 56px;
                    border-radius: 56px;
                    background-size: 24px;
                    font-size: 15px;
                    padding: 0px 20px;
                    background-position: right 20px center;

                }
            }
            .dart_container{
                &.mobile_hidden{
                    display:none;
                }
                &.mobile_view{
                    display:block;
                }
                padding:140px 20px;
            
                text-align: center;
                font-size:18px;
                line-height: 27px;
            }

            .announcement_items {
                margin-top: 48px;
                padding: 0px 16px;

                .an_item {
                   
                    padding: 16px 0px;
                    flex-wrap:wrap;
                    justify-content: flex-start;

                    .an_date {
                        font-size: 14px;
                        width:initial;
                        margin-right:12px;

                    }
                    .an_type{
                        width:36px;
                        height:24px;
                        font-size:13px;
                    }

                    .an_title {
                        flex:initial;
                        margin-top: 12px;
                        font-size: 24px;
                        width: 100%;
                        overflow: initial;
                        text-overflow: initial;
                        white-space: initial;
                    }

                    .an_deco {
                        display: none;
                    }
                }
            }
        }

        &>.announcement_detail {

            padding: 108px 0px;

            .detail_title {
                font-size: 24px;
                padding:0px 16px;
            }

            .detail_contents {
                margin-top: 48px;
                font-size: 16px;
                line-height: 24px;
                padding: 0px 16px;
                .file_section{
                    margin-top:40px;
                }

            }

            .more_bt_section {
                margin-top: 100px;
            }
        }

        &>.policy {

            padding: 108px 0px;

            .section_title {
                font-size: 36px;
            }

            .poli_section {
                margin-top: 48px;
                padding: 0px 16px;

                .poli_title {
                    font-size: 24px;
                    padding-bottom: 24px;
                }

                .poli_items {
					.poli_subtitle{
						font-size:18px;
					}
                    .poli_item {
                        flex-direction: column;
                        padding: 36px 0px 24px;

                        .poli_title {
                            font-size: 15px;
                            padding-bottom: 16px;
                        }

                        .poli_contents {

                            font-size: 16px;
                            line-height: 24px;
                            ;

                            .sub_info {
                                font-size: 15px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }

        .section_container {
            margin-top: 80px;
            flex-direction: column;
            padding: 0px 16px;

            .section_title {
                font-size: 15px;
            }

            .section_contents {
                margin-top: 36px;

                &>.sc_title {
                    font-size: 28px;
                    line-height: 42px;
                    word-break: keep-all;
                    .t_en{
                        font-size:30px;
                    }

                    br {
                        display: none;
                    }
                }

                &>.sc_subtitle {
                    font-size: 18px;
                    line-height: 27px;
                }

                .sc_items {
                    margin-top: 80px;

                    .sc_item {
                        padding: 24px 0px;
                        font-size: 18px;
                        line-height: 27px;
                        flex-direction: column;
                        gap: 24px;

                        .sc_title {
                            font-size: 18px;
                            line-height: 27px;
                            width: 90px;
                            br{
                                display:none;
                            }
                        }

                        .sc_contents {
                            .subinfo {
                                font-size: 18px;
                                line-height: 27px;

                                li {
                                    &:before {
                                        top: 11px;
                                    }
                                }
                            }

                            .input_section {
                                margin-left: -90px;
                                
                                .input_item {
                                    .error_msg{
                                        font-size:16px;
                                    }
                                    input,
                                    textarea {
                                        font-size: 18px;
                                        line-height: 27px;
                                        padding: 15px 0px;
                                       
                                    }
                                    textarea{
                                        margin-top:10px;
                                        padding:5px 0px;
                                        min-height: 100px;
                                    }
                                }

                            }

                            .bt_section {
                                margin-top: 20px;
                                margin-left: -90px;
                            }
                        }

                    }
                }
            }
        }
    }

    .footer {
        margin: 0px;
        padding: 40px 16px 150px;
        height: initial;
        flex-direction: column;
        position: relative;

        .ft_left_section {
            display: none;
        }

        .ft_menu_section {
            width: 40%;
            &.ft_sub_menu{
                margin-top:15px;
               ul li{
                font-size:16px;
               }
            }

            ul li {
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 10px;
            }
        }

        .ft_right_section {
            position: absolute;
            right: 16px;
            top: 40px;
            width: 110px;

            .ft_sub_menu {
                display: none;

            }

            .ft_sns_section {
                margin-top: 0px;
                font-size: 15px;
                gap: 12px;
            }
        }

        .ft_bottom_info_mobile {
            display: block;
            margin-top: 60px;
            font-weight: 500;

            .ft_address {
                font-size: 15px;
                line-height: 21px;
                ;
            }

			.ft_relative{
        	    margin-top:8px;
        	    font-size: 12px;
			}

            .ft_sub_menu {
                font-size: 15px;
                margin-top: 20px;
            }

            .ft_copyright {
                color: #888;

                font-size: 15px;
                margin-top: 20px;
            }
        }

        .ft_top_bt {
            bottom: 20px;
        }
    }

    .default_bt {
        &.more_bt {
            font-size: 13px;
            width: 100px;
            height: 100px;
        }

        &.submit_bt {
            padding: 0px 10px;
            height: 28px;
            font-size: 16px;
        }
    }


}
